import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
	getPaymentMethods,
	setSelectedPackage,
	getCoupon,
	getServersDetails,
	getUserAuthInfo,
	getIDealBankList,
	saveLanguage,
	updateQueryParams,
	getCryptoLogos
} from './store/actions';
import { api } from './store/api';
import { changeCurrency } from './store/reducers/currencySlice';

import { PlanTypes } from './config/plan-types';
import utils from './services/utils';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { LANGUAGES, CURRENCY, REDIRECT_URLS } from './config/main';
import i18n from './i18n';

import Home from './containers/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Verification from './containers/Verification/Verification';
import { Box } from '@material-ui/core';
import { withNamespaces } from 'react-i18next';

const PaymentJs = window.PaymentJs;
const PAYMENT_JS = new PaymentJs();
const cookies = new Cookies();
const { REACT_APP_URL, REACT_APP_RECAPTCHA_SRC } = process.env;

const App = ({
	t,
	currency,
	onGetPaymentMethods,
	onSetSelectedPackage,
	queryParams,
	onGetCoupon,
	onGetServersDetails,
	onGetUserAuthInfo,
	onGetIDealBankList,
	onSaveLanguage,
	onChangeCurrency,
	onUpdateQueryParams,
	onGetCryptoLogos
}) => {
	const [onCheckSecurity] = api.useOnCheckSecurityMutation();
	const [onGetCurrency] = api.useOnGetCurrencyMutation();
	const [onGetPackagesList] = api.useOnGetPackagesListMutation();
	const [lang, updateLanguage] = useState(utils.initLang(LANGUAGES));
	const [firstLoad, updateFirstLoad] = useState(true);
	const [upgradeMode, updateUpgradeMode] = useState(false);
	const history = createBrowserHistory();
	const navigate = useNavigate();
	const location = useLocation();
	let homeProps = {onSetSelectedPackage, paymentJS: PAYMENT_JS, queryParams, history, navigate, enableAbandonmentModal: true, disableProxyPopup: false};

	const handleUpdateQueryParams = (queryParams) => {
		onUpdateQueryParams({...queryParams});
		homeProps = {...homeProps, ...queryParams};
	};

	const handleChangeLanguage = (lang, savedLang) => {
		const {email, username, authInfoSuccess} = queryParams;
		const access_token = sessionStorage?.getItem('PV_access_token') || '';
		const _lang = lang === 'en' ? '' : lang;
		updateLanguage(_lang);
		if(!savedLang && email && authInfoSuccess) {
			onSaveLanguage({email, username, access_token, current_language: lang});
		}
	};

	const combineRoute = (path, currency) => {
		return utils.combineRoute(lang, path, currency);
	};

	useEffect(() => {
		if(queryParams.language) {
			handleChangeLanguage(queryParams.language, true);
		}
	}, [queryParams.language]);

	useEffect(() => {
		window.recaptchaOptions = {
			useRecaptchaNet: (REACT_APP_RECAPTCHA_SRC === 'useRecaptchaNet'),
		};
	}, []);

	useEffect(() => {
		const _LANGUAGES = LANGUAGES.map(l => l.lang);
		const _lang = (!lang || _LANGUAGES.indexOf(lang) === -1) ? '/' : `/${lang}/`;
		const _path = location.pathname || '';
		const _currentLang = _LANGUAGES.find(l => _path.indexOf('/' + l + '/') > -1) || '';
		let _route = _path.replace('/' + _currentLang + '/', '');
		_route = (_route.charAt( 0 ) === '/') ? _route.slice(1) : _route;

		if(firstLoad) {
			if(/\/\/}?$/.test(location.pathname)) {
				navigate(`${_lang}${_route}`);
			}
			updateFirstLoad(false);
		} else {
			navigate(`${_lang}${_route}`);
		}
		i18n.changeLanguage(lang || 'en');
		document.documentElement.lang = lang || 'en';
		document.documentElement.className = `lang-${lang || 'en'}`;
	}, [lang]);

	useEffect(() => {
		const _queryParams = queryString.parse(location.search, {arrayFormat: 'index'});
		const _returnUrl = sessionStorage?.getItem('returnUrl');
		const _token = _queryParams.token || sessionStorage?.getItem('PV_token');
		const _detectedLang = LANGUAGES.map(l => l.lang).find(lang => location.pathname.indexOf('/' + lang + '/') > -1) || cookies.get('userLang') || (navigator.language || '').split('-')[0] || 'en';
		const _routeLang = utils.initLang(LANGUAGES) || 'en';
		const _path = ((location.pathname[location.pathname.length - 1] === '/') ? location.pathname.slice(0, -1) : location.pathname);

		handleUpdateQueryParams(_queryParams);

		//redirects
		if(location.pathname.indexOf(combineRoute('/black-friday-2024')) > -1) {
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/winter-special' + location.hash + location.search;
		} else if(location.pathname.indexOf(combineRoute('/black-friday')) > -1) {
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/winter-special-for-you' + location.hash + location.search;
		} else if(REDIRECT_URLS['special-offer'].map(r => combineRoute(r)).indexOf(_path) > -1) {
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/special-offer' + location.search;
		} else if(REDIRECT_URLS['limitedoffer'].map(r => combineRoute(r)).indexOf(_path) > -1) {
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/limitedoffer' + location.search;
		} else if(REDIRECT_URLS['offer'].map(r => combineRoute(r)).indexOf(_path) > -1) {
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/offer' + location.search;
		} else if(_returnUrl) {
			window.location.href = window.location.origin +  '/' + _returnUrl;
			sessionStorage?.removeItem('returnUrl');
		} else if(location.pathname.indexOf(combineRoute('/upgrade')) > -1 && !_token) {
			window.location.href = REACT_APP_URL + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/upgrade';
		} else if(location.pathname.indexOf(combineRoute('/cancel/offer')) > -1 && !_token) {
			window.location.href = window.location.origin;
		} else if(location.pathname.indexOf(combineRoute('/upgrade')) === -1 && _detectedLang && (LANGUAGES.map(l => l.lang).indexOf(_detectedLang) > -1) && (_detectedLang !== _routeLang || location.pathname.indexOf('/en') > -1)) {
			const _route = utils.getRoute(LANGUAGES);
			window.location.href = window.location.origin + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + (_route === '/' ? _route : ('/' + _route)) + location.search;
		} else if(firstLoad) {
			onCheckSecurity().unwrap().then(resp => {
				window.dataLayer = window.dataLayer || [];
				if(!resp?.is_blocked) {
					onGetCurrency({countryCode: resp?.iso_code, savedCurrency: cookies.get('currencyCode')}).unwrap().then(available_currencies => {
						const currency = cookies.get('currencyCode') || (CURRENCY[_queryParams.currency] ? _queryParams.currency : available_currencies[0]) || 'USD';
						onChangeCurrency(currency);
						onGetPackagesList().unwrap().then((packagesList = []) => {
							if(_queryParams.coupon) {
								sessionStorage?.setItem('queryCoupon', _queryParams.coupon);
							}
							if(_queryParams.username) {
								sessionStorage?.setItem('username', _queryParams.username);
							}
							let _selectedPackage = packagesList.filter(p => p.packageIdentifier === PlanTypes.YEARLY)[0] || {};
							onSetSelectedPackage(_selectedPackage);
							onGetCoupon({
								coupon_code: _queryParams.coupon || sessionStorage?.getItem('queryCoupon') || utils.getRoute(LANGUAGES) || '/',
								package_id: PlanTypes.YEARLY,
								addons: (location.pathname.indexOf(combineRoute('/cancel/offer')) > -1 ? ['SENTRYS'] : []) 
							}).then(() => {
								const _existingMeta = cookies.get('privadometa') || {};
								const _referrer = window.document.referrer || '';
								let _meta = {};
								let _referrerMeta = {};

								if(_queryParams.email) {
									_queryParams.email = _queryParams.email.replace(/\s+/g, '+');
								}
								if(sessionStorage?.getItem('queryCoupon')) {
									_queryParams.coupon = sessionStorage?.getItem('queryCoupon');
								}

								handleUpdateQueryParams(_queryParams);

								if(location.search) {
									_meta = utils.filterMeta(_queryParams) || {};
									navigate(location.pathname + '?' + (Object.keys(_meta).length ? queryString.stringify(_meta) : ''));
								}
								if(_referrer.length) {
									const _referrerUrl = new URL(_referrer);
									_referrerMeta = {ReferrerDomain: _referrerUrl.host, ReferrerPath: _referrerUrl.pathname};
								}
								if(Object.keys({..._meta, ..._referrerMeta, ..._existingMeta}).length) {
									cookies.set('privadometa', JSON.stringify({..._meta, ..._referrerMeta, ..._existingMeta}), { path: '/', domain: window.location.hostname });
								}

								if(_token) {
									const _dataForUserAuthInfo = {
										token: _token,
										password: '',
										package_id: _selectedPackage.packageIdentifier,
										amount: _selectedPackage.recurringPeriod?.basePrice.filter(bp => bp.currencyCode === currency)[0].amount,
										currency
									};
									onGetUserAuthInfo(_dataForUserAuthInfo)
										.then(response => {
											if(response.data && response.data.email) {
												const _restParams = response.data;
												_restParams.authInfoSuccess = true;
												_restParams.userstatus = _restParams.user_status;
												_restParams.token = _token;

												if(location.pathname.indexOf(combineRoute('/upgrade')) > -1) {
													updateUpgradeMode(true);

													// BF24
													if(response.data.current_plan !== PlanTypes.YEARLY || response.data.current_plan !== PlanTypes.YEARLY2) {
														_restParams.biannualcoupon = 'WS2025-24mo-upgrade';
														onGetCoupon({
															coupon_code: 'WS2025-12mo-upgrade',
															package_id: PlanTypes.YEARLY,
															addons: []
														});
													}
												}

												handleUpdateQueryParams({..._queryParams, ..._restParams});
												sessionStorage?.setItem('PV_token', _token);
											} else {
												handleUpdateQueryParams({..._queryParams, token: ''});
												let _redirectUrl = REACT_APP_URL + (_detectedLang === 'en' ? '' : ('/' + _detectedLang)) + '/sign-in?redirect_url=' + window.location.href;
												if(response.code === 404) {
													_redirectUrl += '&logout=1';
												}
												if(location.pathname.indexOf(combineRoute('/upgrade')) > -1) {
													setTimeout(() => {
														window.location.href = _redirectUrl;
													}, 400);
												}
											}
										});
								}
							});
						});
						onGetPaymentMethods({currency, selected_country: resp?.iso_code, show_all_methods_with_no_limitations: (location.pathname.indexOf('bfa9b7aa5dfd833775f3cf093846b04d') > -1)});
					});
					onGetIDealBankList();
					onGetServersDetails();
					onGetCryptoLogos();
					window.dataLayer.push({
						'event' : 'geo',
						'countryCode': resp?.iso_code
					});
				} else {
					window.dataLayer.push({
						'event' : 'sanctionedCountry',
					});
				}
			});
		}
	}, [onCheckSecurity, onGetPackagesList, onGetPaymentMethods, onSetSelectedPackage, onGetCoupon, onGetServersDetails, onGetUserAuthInfo, onGetIDealBankList, onGetCryptoLogos]);

	return (
		<>
			<CssBaseline />
			
			<Routes>
				{LANGUAGES.map(l => (
					<Route key={l.lang}>
						<Route path={(l.lang === 'en') ? '/' : l.lang} element={
							<>
								<Header onChangeLanguage={handleChangeLanguage} />
								<Home {...homeProps} 
									planConfig={{
										sentry: true,
										title: 'Get the best 2024 VPN deal',
										hideSubTitle: true,
										hideFree: true,
										showFreeBtn: true,
										showYearly2: true,
										showPlansSeparator: true,
										showSkuPricePerMonth: true,
										hideBill: true,
										saleTimerBanner: true,
										saleTimerBgClassName: 'bannerBg1',
										saleTimerTitle: 'VPN Deal!',
										couponYearly2: 'WS2025-24mo-homepage',
										couponMonthly: '1mo1099-homepage',
									}} 
								/>
								<Footer V2 />
							</>
						} />
						<Route path={(l.lang === 'en') ? '/' : l.lang} >
							<Route path="upgrade" element={
								<>
									<Header appUrl={'/' + i18n.resolvedLanguage + '/admin-panel'} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} enableAbandonmentModal={false} upgradeMode={upgradeMode} 
										planConfig={{
											sentry: true,
											title: 'Get the best 2024 VPN deal',
											showSkuPricePerMonth: true,
											hideBill: true,
											saleTimerBanner: true,
											saleTimerBgClassName: 'bannerBg1',
											saleTimerTitle: 'VPN Deal!',
											couponYearly2: '24mo199-upgrade',
											couponMonthly: '1mo1099-upgrade',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="get" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Get{$}Unlimited VPN',
											hideFree: true,
											hideYearlyBadge: true,
											showYearly2: true,
											showPlansSeparator: true,
											showFreeBtn: true,
											showSkuPricePerMonth: true,
											couponYearly2: '24mo148-upgrade',
											couponMonthly: '1mo1099-upgrade',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Try PrivadoVPN Risk-Free',
											hideMonthly: true,
											showShortPlans: true,
											showPlansSeparator: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="cart-free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} />
									<Footer V2 />
								</>
							} />
							<Route path="get-privadovpn" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'get-privadovpnm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="free-recommended" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'getprivadovpn-10'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="get-deal" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'PREMIUM1099'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="get-deal-trial" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'PREMIUM1099'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="great-deal" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: 'minimal1mo-ppc',
											couponYearly2: 'minimal24mo-ppc',
											couponInterstitial: 'additionaloptions30'
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="great-deal-trial" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'PREMIUM1099'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="get-unlimited-vpn" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'MONTHLY1099'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="get-unlimited-vpn-free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'MONTHLY1099'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="chipde" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'chipdem'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="chipde-free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'chipdem'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="computerbild" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'computerbildm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="computerbild-free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'computerbildm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="netzweltde" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'netzweltm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="netzweltde-free" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'netzweltm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="cart" element={
								<>
									<Header logoUrl={combineRoute('', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											title: 'Get{$}Unlimited VPN',
											monthlyOrder: 3,
											yearlyOrder: 1,
											hideYearlyBadge: true,
											showYearly2: true,
											hideFree: true
										}}
									/>
									<Footer V2 />
								</>
							} />
							{['order', 'best-offer'].map((path, index) =>
								<Route path={path} key={index + path} element={
									<>
										<Header logoUrl={combineRoute((path === '/best-offer') ? '/best-offer' : '', currency)} onChangeLanguage={handleChangeLanguage} />
										<Home {...homeProps}
											planConfig={{
												hideFree: true
											}}
										/>
										<Footer V2 />
									</>
								} />
							)}
							{['bestdeal', 'cart2'].map((path, index) =>
								<Route path={path} key={index + path} element={
									<>
										<Header logoUrl={combineRoute('/best-vpn', currency)} onChangeLanguage={handleChangeLanguage} />
										<Home {...homeProps}
											planConfig={{
												hideFree: true
											}}
										/>
										<Footer V2 />
									</>
								} />
							)}
							{['bestvpnoffer', 'bestvpn'].map((path, index) =>
								<Route path={path} key={index + path} element={
									<>
										<Header onChangeLanguage={handleChangeLanguage} />
										<Home {...homeProps}
											planConfig={{
												hideFree: true
											}}
										/>
										<Footer V2 />
									</>
								} />
							)}
							<Route path="verification" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Verification navigate={navigate} />
									<Footer isEmpty />
								</>
							} />
							<Route path="bestvpntrial" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} />
									<Footer V2 />
								</>
							} />
							<Route path="welcome-back" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											showOnlyYearly: true,
											pageTitle: 'Reactivate your PrivadoVPN account'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="offer" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the best Holiday deal!',
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: '1mo1099-affiliate',
											couponYearly2: '24mo148intro-affiliate',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="getstarted" element={
								<>
									<Header logoUrl={combineRoute('/getstarted', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											monthlyOrder: 3,
											hideRenew: true,
											hideFree: true,
											yearlyPriceAlways: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-deal-free" element={
								<>
									<Header logoUrl={combineRoute('/special-deal', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideOldPrice: true,
											featuredOn: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-deal" element={
								<>
									<Header logoUrl={combineRoute('/special-deal', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree:true,
											hideOldPrice: true,
											featuredOn: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-deal-free-b" element={
								<>
									<Header logoUrl={combineRoute('/special-deal-free-b', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideOldPrice: true,
											featuredOn: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-deal-b" element={
								<>
									<Header logoUrl={combineRoute('/special-deal-b', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree:true,
											hideOldPrice: true,
											featuredOn: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="offer-free" element={
								<>
									<Header logoUrl={combineRoute('/offer-free', currency)} onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											yearlyPriceAlways: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-offer1-30" element={
								<>
									<Header logoUrl={combineRoute('/special-offer1-30', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											onlyNewPrice: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-offer-30" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											aimtellBanner: true,
											monthlyOrder: 3,
											yearlyOrder: 1,
											hideFree: true,
											hideYearlyBadge: true,
											hideYearlyMBG: true,
											showYearly2: true,
											showPlansSeparator: true,
											couponYearly2: '24aff199'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="spyoff-special-offer-intro-30" element={
								<>
									<Header logoUrl={combineRoute('/spyoff-special-offer-intro-30', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											onlyNewPrice: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-offer-20" element={
								<>
									<Header logoUrl={combineRoute('/special-offer-20', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											onlyNewPrice: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-offer-intro-20" element={
								<>
									<Header logoUrl={combineRoute('/special-offer-intro-20', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											onlyNewPrice: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="specialoffer" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} />
									<Footer V2 />
								</>
							} />
							<Route path="student-discount" element={
								<>
									<Header logoUrl={combineRoute('/student-discount', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											onlyNewPrice: true,
											savingPercent: '77%'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="premium-recommended" element={
								<>
									<Header logoUrl={combineRoute('/premium-recommended', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 77% off your first year. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Get 77% Off Unlimited Data for a Full Year',
											modalTitle: 'Get 77% Off PrivadoVPN Today',
											hideFree: true,
											onlyNewPrice: true,
											savingPercent: '77%'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="limitedvpnoffer-b" element={
								<>
									<Header logoUrl={combineRoute('/limitedvpnoffer-b', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 77% off your first year. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											onlyNewPrice: true,
											savingPercent: '77%'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="best-free-vpn" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the best Holiday deal!',
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											couponMonthly: '1mo1099-future',
											couponYearly2: '24mo148intro-future',
											couponInterstitial: 'additionaloptions30',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="limitedoffer" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the best Holiday deal!',
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											couponMonthly: '1mo1099-affiliate',
											couponYearly2: '24mo148intro-affiliate',
											couponInterstitial: 'additionaloptions30',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="promo" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Our Best Deal!',
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											showSkuSubtitle: true,
											couponMonthly: '1mo1099-affiliate',
											couponYearly2: '24mo148intro-affiliate',
											couponInterstitial: 'additionaloptions30',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
											saleTimerTitle: 'VPN Deal!',
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="limitedvpnoffer" element={
								<>
									<Header logoUrl={combineRoute('/limitedvpnoffer', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 82% off your first 2 years. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Exclusive Offer:{$}Unlimited VPN',
											subTitle: 'Save 82% on unlimited data, unlimited devices with up to 10 simultaneous connections, ad blocker, and more!',
											onlyNewPrice: true,
											monthlyOrder: 3,
											yearlyOrder: 1,
											hideFree: true,
											hideYearlyBadge: true,
											showYearly2: true,
											showPlansSeparator: true,
											couponYearly2: '24aff199'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="authority" element={
								<>
									<Header logoUrl={combineRoute('/authority', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 77% off your first year. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											onlyNewPrice: true,
											savingPercent: '77%'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="limitedtimeoffer" element={
								<>
									<Header logoUrl={combineRoute('/limitedtimeoffer', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 77% off your first year. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Get 77% Off Unlimited Data for a Full Year',
											modalTitle: 'Get 77% Off PrivadoVPN Today',
											planShowBill: true,
											onlyNewPrice: true,
											savingPercent: '77%'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="digitalsupermarket" element={
								<>
									<Header logoUrl={combineRoute('/digitalsupermarket', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Get a special deal on the fastest, most secure VPN on the planet." />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Get 77% Off Unlimited Data for a Full Year',
											modalTitle: 'Get 77% Off PrivadoVPN Today',
											onlyNewPrice: true
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="getvpnaccount" element={
								<>
									<Header logoUrl={combineRoute('/getvpnaccount', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Sign up for PrivadoVPN Premium today and get 77% off your first year. 30 day Money Back Guarantee, no strings attached. Try PrivadoVPN Premium out today!" />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Get 77% Off Unlimited Data for a Full Year',
											modalTitle: 'Get 77% Off PrivadoVPN Today',
											onlyNewPrice: true,
											savingPercent: '77%',
											couponMonthly: 'get-privadovpnm'
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="vpn-offer" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'vpn-offer1m'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="signupnow" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'signupnowm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="netzwelt" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											couponMonthly: 'netzweltm'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="getprivado" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps}
										planConfig={{
											hideFree: true,
											couponMonthly: 'premiumaffiliate10'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="bfa9b7aa5dfd833775f3cf093846b04d" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} />
									<Footer V2 />
								</>
							} />
							<Route path="start" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											couponYearly2: 'riskfree47',
											couponInterstitial: 'additionaloptions30'
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="best-free-vpn-b" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											couponMonthly: 'minimal1mo-aff',
											couponYearly2: 'minimal24mo-aff',
											couponInterstitial: 'additionaloptions30'
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="limitedoffer-b" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											showFreeBtn: true,
											couponMonthly: 'minimal1mo-fut',
											couponYearly2: 'minimal24mo-fut',
											couponInterstitial: 'additionaloptions30'
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="great-deal-b" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: 'minimal1mo-ppc',
											couponYearly2: 'minimal24mo-ppc',
											couponInterstitial: 'additionaloptions30'
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="black-friday" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Get the Best Black Friday VPN Deal',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showSkuPricePerMonth: true,
											hideBill: true,
											saleTimerBanner: true,
											saleTimerBgClassName: 'bannerBg1',
											saleTimerTitle: 'VPN Deal!',
											couponYearly2: 'BF2024-24mo-winback',
											couponMonthly: '1mo1099-homepage',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="black-friday-deal" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Home V3 {...homeProps} 
										planConfig={{
											title: 'Get the best Holiday deal!',
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: '1mo1099-ppc',
											couponYearly2: 'BF2023-24mo111-ppc',
											showSkuPricePerMonth: true,
											planShowBill: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="black-friday-offer" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Get the best Holiday deal!',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showFreeBtn: true,
											couponYearly2: 'BF2023-24mo111-upgrade',
											couponMonthly: '1mo1099-upgrade',
											showSkuPricePerMonth: true,
											planShowBill: true,
											saleTimerBanner: true
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="special-offer" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Get the best 2024 VPN deal',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showFreeBtn: true,
											showSkuPricePerMonth: true,
											hideBill: true,
											saleTimerBanner: true,
											saleTimerBgClassName: 'bannerBg1',
											saleTimerTitle: 'VPN Deal!',
											couponYearly2: '24mo199-homepage',
											couponMonthly: '1mo1099-homepage',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="streaming" element={
								<>
									<Header logoUrl={combineRoute('/streaming', currency)} onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<title>{t('Get The Best VPN For Streaming | PrivadoVPN')}</title>
										<meta name="description" content={t('Use PrivadoVPN for secure streaming, music, games, and more.')} />
									</Helmet>
									<Home {...homeProps}
										planConfig={{
											title: 'Streaming Special:<br/>{$}/mo Unlimited VPN + 3 Months Free',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showFreeBtn: true,
											showSkuPricePerMonth: true,
											hideBill: true,
											couponYearly2: '24mo199-homepage',
											couponMonthly: '1mo1099-homepage'
										}}
									/>
									<Footer V2 />
								</>
							} />
							<Route path="vpn-deal" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the Best VPN for only{$}',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showSkuPricePerMonth: true,
											hideBill: true,
											couponYearly2: '24mo199-brazil',
											couponMonthly: '1mo1099-brazil',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="cancel/offer" element={
								<Box className="v3">
									<Header 
										showSubHeader
										queryParams={queryParams}
										onChangeLanguage={handleChangeLanguage} />
									<Home V23 {...homeProps} planConfig={{
										title: 'Exclusive Loyalty Offer',
										subTitle: 'Don\'t miss out on Unlimited VPN and Free Antivirus for only {$}/mo!',
										cancelOfferMode: true,
										monthlyOrder: 1,
										hidePlanSectionTitle: true,
										hideFree: true,
										hideEmail: true,
										hideLogos: true,
										sentry: true,
										freeSentry: true,
										couponMonthly: '1mo1099-cancel',
									}} />
									<Footer V3 />
								</Box>
							} />
							<Route path="black-friday-2024" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the best Black Friday deal!',
											saleTimerTitleArray: ['Black Friday', 'VPN Deal!'],
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: '1mo1099-affiliate',
											couponYearly2: 'BF2024-24mo-affiliate',
											couponInterstitial: 'additionaloptions30',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
							<Route path="winter-special-for-you" element={
								<>
									<Header onChangeLanguage={handleChangeLanguage} />
									<Home {...homeProps} 
										planConfig={{
											title: 'Get the Best Holiday VPN Deal',
											hideSubTitle: true,
											hideFree: true,
											showYearly2: true,
											showPlansSeparator: true,
											showSkuPricePerMonth: true,
											hideBill: true,
											saleTimerBanner: true,
											saleTimerBgClassName: 'bannerBg1',
											saleTimerTitle: 'VPN Deal!',
											couponYearly2: 'WS2025-24mo-winback',
											couponMonthly: '1mo1099-homepage',
										}} 
									/>
									<Footer V2 />
								</>
							} />
							<Route path="winter-special" element={
								<Box className="v3">
									<Header V3 onChangeLanguage={handleChangeLanguage} />
									<Helmet>
										<meta name="description" content="Protect yourself online with PrivadoVPN, featuring a Zero-Log server network protected by Swiss-privacy laws." />
									</Helmet>
									<Home V3 {...homeProps} 
										planConfig={{
											sentry: true,
											title: 'Get the best Holiday deal!',
											saleTimerTitleArray: ['Holiday', 'VPN Deal!'],
											monthlyOrder: 3,
											yearlyOrder: 1,
											showYearly2: true,
											hideFree: true,
											couponMonthly: '1mo1099-affiliate',
											couponYearly2: 'WS2025-24mo-affiliate',
											couponInterstitial: 'additionaloptions30',
											showSkuPricePerMonth: true,
											planShowBillShort: true,
											saleTimerBanner: true,
										}} 
									/>
									<Footer V3 />
								</Box>
							} />
						</Route>
						<Route path ='*' element={<Navigate to="/" replace />} />
					</Route>
				))}
			</Routes>
			<ToastContainer />
		</>
	);
};

const mapStateToProps = state => {
	return {
		currency: state.securityCheck.securityDetails.current_currency || 'USD',
		queryParams: state.home.queryParams
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetPaymentMethods: (dataToSend) => dispatch(getPaymentMethods(dataToSend)),
		onSetSelectedPackage: p => dispatch(setSelectedPackage(p)),
		onGetCoupon: (dataToSend) => dispatch(getCoupon(dataToSend)),
		onGetServersDetails: () => dispatch(getServersDetails()),
		onGetUserAuthInfo: data => dispatch(getUserAuthInfo(data)),
		onGetIDealBankList: () => dispatch(getIDealBankList()),
		onSaveLanguage: (data) => dispatch(saveLanguage(data)),
		onChangeCurrency: (current_currency) => dispatch(changeCurrency(current_currency)),
		onUpdateQueryParams: (queryParams) => dispatch(updateQueryParams(queryParams)),
		onGetCryptoLogos: () => dispatch(getCryptoLogos()),
	};
};

App.propTypes = {
	queryParams: PropTypes.object,
	currency: PropTypes.string,
	onGetPaymentMethods: PropTypes.func,
	onSetSelectedPackage: PropTypes.func,
	onGetCoupon: PropTypes.func,
	onGetServersDetails: PropTypes.func,
	onGetUserAuthInfo: PropTypes.func,
	onGetIDealBankList: PropTypes.func,
	onSaveLanguage: PropTypes.func,
	onChangeCurrency: PropTypes.func,
	onUpdateQueryParams: PropTypes.func,
	onGetCryptoLogos: PropTypes.func,
	t: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(App));
