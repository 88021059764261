import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PlanTypes } from '../../../config/plan-types';
import { CURRENCY } from '../../../config/main';
import { withNamespaces, Trans } from 'react-i18next';
import utils from '../../../services/utils';
import {
	Box,
	Paper,
	Typography,
	Button
} from '@material-ui/core';
import MBG from '../../UI/MBG/MBG';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './PaymentInfoV3Styles';
import { connect } from 'react-redux';
import { removeCouponByPlan, updateSentryProps } from '../../../store/actions';

const { REACT_APP_CREDIT_CARD, REACT_APP_PAYPAL_FLEXIBLE, REACT_APP_WEB } = process.env;

function PaymentInfoV3({
	t,
	i18n,
	_pft,
	_pfe,
	paymentNotEligible = false,
	paymentDisplayName,
	planConfig,
	savingPercent,
	saving2Percent,
	saving,
	selectedPackage,
	classes,
	customClassName,
	currency,
	couponCode,
	couponByCode,
	couponByPlan,
	couponPrice,
	couponGracePeriod = 0,
	couponValidPlan,
	couponRenewalPrice,
	couponSavingPercent,
	upgradeMode,
	disabled,
	btnProcessing,
	isProxyDetected,
	mbg,
	info,
	amexWarning = false,
	sentryProps,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmit,
	onRemoveCouponByPlan,
	onUpdateSentryProps
}) {
	const _currency = CURRENCY[currency] || currency;
	const _basePrice = ((selectedPackage?.recurringPeriod?.basePrice) || []).find(b => b.currencyCode === currency);
	const _amount =  _basePrice?.amount || 0;
	const _renewalPrice = selectedPackage.recurringPeriod.renewalPrice || 0;
	const _gracePeriod = selectedPackage.recurringPeriod.grace_period_months || 0;
	const _baseSentryPrice = ((selectedPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
	const _baseSentryMonths = Math.floor(((selectedPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.length || 0)/30);
	const _amountSentry = !planConfig.freeSentry && _baseSentryPrice?.amount || 0;
	const _amountSentryTotal = !couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? ((selectedPackage.packageIdentifier === couponValidPlan ? couponGracePeriod : _gracePeriod) || _baseSentryMonths)*_amountSentry : 0;

	const handleRemoveCouponByPlan = () => {
		onRemoveCouponByPlan(couponByPlan?.code);
		if(couponByPlan?.code === couponCode) {
			onChangeCouponCodeField('');
		}
	};

	const handleUpdateSentryProps = () => {
		onUpdateSentryProps({
			...sentryProps,
			[selectedPackage.packageIdentifier]: true
		});
	};

	return selectedPackage.packageIdentifier ? (
		<Paper elevation={0} className={clsx(classes.paymentInfoContainer, (customClassName) ? classes[customClassName] : null)}>
			{!planConfig.cancelOfferMode && (selectedPackage.packageIdentifier === PlanTypes.YEARLY2 || selectedPackage.packageIdentifier === PlanTypes.YEARLY) ?
				<Box display="flex" justify="center" className={classes.paymentInfoSaving}>
					{t('You Are Saving ') + ' ' + ((selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? saving2Percent : savingPercent) || planConfig.savingPercent || couponSavingPercent)}
				</Box>:
				null
			}

			<Box p={1}>
				{paymentDisplayName === 'Coinbase' || paymentDisplayName === 'Coinbill' ?
					<Box mb={1} className={classes.paymentNotEligible}>
						{t('Purchases with cryptocurrency are not eligible for refund.')}
					</Box>: null
				}
				{paymentNotEligible ? 
					<Box mb={1} className={classes.paymentNotEligible}>
						<Trans i18nKey="transPaymentCouponRestriction">
							{{pcrestriction1: paymentDisplayName}} is not eligible for coupon {{pcrestriction2: couponByPlan?.code}}. Select <a onClick={() => onChangePaymentMethod(REACT_APP_CREDIT_CARD)}>Credit Card</a> or <a onClick={() => onChangePaymentMethod(REACT_APP_PAYPAL_FLEXIBLE)}>PayPal</a> to use this coupon, or remove the coupon to use {{pcrestriction3: paymentDisplayName}}.
						</Trans>
					</Box> :
					null
				}

				{!planConfig.cancelOfferMode && selectedPackage.packageIdentifier !== PlanTypes.FREE ?
					<Box display="flex" justifyContent="space-between">
						<Box>{t((selectedPackage.packageIdentifier === PlanTypes.MONTHLY) ? '1 Month Plan Price:' : 'Regular Price:')}</Box>
						<Box className={clsx({[classes.lineThrough]: (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2)})}>{_currency + (selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? (couponValidPlan === PlanTypes.MONTHLY ? +couponPrice : _amount) : saving.regularPrice).toFixed(2)}</Box>
					</Box>: null
				}

				{!planConfig.cancelOfferMode && (selectedPackage.packageIdentifier === PlanTypes.YEARLY || selectedPackage.packageIdentifier === PlanTypes.YEARLY2) ?
					<Box display="flex" justifyContent="space-between" pt={1}>
						<Box className={classes.discount}><span>{t('Discount: ')}</span></Box>
						<Box className={clsx(classes.discount, classes.textGreen)}>{'-' + _currency + saving.discount.toFixed(2)}</Box>
					</Box> : null
				}

				{!planConfig.cancelOfferMode && planConfig.sentry && _amountSentry ? 
					<>
						<Box display="flex" justifyContent="space-between" className={clsx(classes.paymentInfoBlock, classes.paymentInfoSentry)}>
							{sentryProps[selectedPackage.packageIdentifier] ?
								<Box>{t('Antivirus')}</Box>:
								<Box>{t('Add Antivirus for') + ' (' +  _currency + _amountSentry + t('/mo') + ')'}</Box>
							}
							<Box fontWeight={700}>
								{sentryProps[selectedPackage.packageIdentifier] ? 
									(_currency + _amountSentryTotal ): 
									<span className={classes.paymentInfoAddSentry} onClick={handleUpdateSentryProps}>{t('Add')}&nbsp;+</span>
								}
							</Box>
						</Box>
						<Box className={classes.paymentInfoSeparator}/>
					</>:
					null
				}

				{couponByPlan?.code && paymentNotEligible ? 
					<Box display="flex" justifyContent="space-between" pt={1} pb={1}>
						<span>{couponByPlan?.code}</span>
						<span className={classes.btnLink}  onClick={handleRemoveCouponByPlan}>{t('Remove')}</span>
					</Box>: null
				}

				{planConfig.cancelOfferMode ? null :<Box className={classes.separator} pt={2} mb={2} />}

				{planConfig.cancelOfferMode ? null : 
					<Box display="flex" justifyContent="space-between" className={classes.total}>
						<Box>{t('Today\'s Total:')}</Box>
						<Box>
							{_currency + (((selectedPackage.packageIdentifier === couponValidPlan) ? (+couponPrice || _amount) : _amount) + _amountSentryTotal).toFixed(2)}
						</Box>
					</Box>
				}

				<Box className={classes.termsContainer}>
					<div className={classes.terms}>
						<div>
							<Trans i18nKey="transTermsAndConditionsNew">
								I have read and agree to the <a color="textPrimary" href={REACT_APP_WEB + utils.combineRoute((i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage), '/terms-of-service/')} target="_blank" rel="noopener noreferrer" >Terms of Service</a> and <a color="textPrimary" href={REACT_APP_WEB + utils.combineRoute((i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage), '/privacy-policy/')} target="_blank" rel="noopener noreferrer" >Privacy Policy</a>.
							</Trans>
						</div>
						{selectedPackage.packageIdentifier === PlanTypes.YEARLY ?
							(couponValidPlan === PlanTypes.YEARLY) ?
								(couponGracePeriod || couponRenewalPrice) ?
									<Trans i18nKey="transBilledGracePeriodMonths"> 
										Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponPrice + _amountSentryTotal).toFixed(2)}} for the first {{transGracePeriod: couponGracePeriod || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
									</Trans>:
									<Trans i18nKey="transBilledEvery12MonthsAlways">
										Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
									</Trans>: 
								(_gracePeriod || _renewalPrice) ?
									<Trans i18nKey="transBilledGracePeriodMonths">
										Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount + _amountSentryTotal).toFixed(2)}} for the first {{transGracePeriod: ('12 + ' + (_gracePeriod - 12)) || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+_renewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months. 
									</Trans> : 
									<Trans i18nKey="transBilledEvery12MonthsAlways">
										Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
									</Trans> :
							null
						}

						{selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? 
							(couponValidPlan === PlanTypes.YEARLY2) ?
								(couponGracePeriod || couponRenewalPrice) ?
									<Trans i18nKey="transBilled24GracePeriodMonths">
										Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponPrice + _amountSentryTotal).toFixed(2)}} for the first {{trans24GracePeriod: couponGracePeriod || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
									</Trans>:
									<Trans i18nKey="transBilled24Months">
										Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
									</Trans>:
								(_gracePeriod || _renewalPrice) ?
									<Trans i18nKey="transBilled24GracePeriodMonths">
										Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount + _amountSentryTotal).toFixed(2)}} for the first {{trans24GracePeriod: ('24 + ' +(_gracePeriod  - 24)) || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+_renewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
									</Trans> : 
									<Trans i18nKey="transBilled24Months">
										Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
									</Trans> :
							null
						}
						
						{selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? 
							<Trans i18nKey="transBilledEveryMonth">
								Billed {{transBilledEveryMonth: (_currency + (((selectedPackage.packageIdentifier === couponValidPlan) ? (+couponPrice || _amount) : _amount) + _amountSentryTotal).toFixed(2))}} every month.
							</Trans> :
							null
						}

						{paymentDisplayName === 'Cryptocurrency' ? (<div>{t('Purchases with cryptocurrency are not eligible for refund.')} </div>) : null}
					</div>
					{_pft.termsAndConditions && _pfe.termsAndConditions ?
						<Typography component="p" className={classes.errorText}>
							<span className={classes.errorIcon}>{_pfe.termsAndConditions}</span>
						</Typography> : null
					}
				</Box>

				{amexWarning ? 
					<Box component="div" pt={3} className={classes.amexWarning}>
						<Box component="span" className={classes.errorIcon}>
							{t('Unfortunately EUR payments aren\'t available for AmEx cards at the moment. Please select a different card type or payment method. Thank you.')}
						</Box>
					</Box> : null 
				}

				<Box className={classes.submitContainer} mt={2} display="flex" justifyContent="center">
					<div>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disabled={disabled || amexWarning}
							onClick={onSubmit}
						>
							{btnProcessing ? <LoaderSmall /> : null}
							{t(btnProcessing ? 'Processing' : upgradeMode ? 'Confirm Purchase' : 'Get Now')}

						</Button>
						{isProxyDetected ?
							<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
						}
					</div>
				</Box>

				{info ? 
					<Box className={classes.info}>
						{t('I authorize Privado Networks AG (https://privadovpn.com/) to send instructions to my bank to debit my account every 12 months. At the same time I authorize my bank to debit my account in accordance with the instructions from Privado Networks AG.')}
					</Box>: null 
				}
			</Box>

			{mbg && (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2) ?
				<Box display="flex" alignItems="center" justifyContent="space-between" className={classes.moneyBack}>
					<MBG />
					<Box ml={1}>
						<div className={classes.moneyBackTitle}>{t('30-Day Money Back Guarantee')}</div>
						<div className={classes.moneyBackContent}>{t('Try PrivadoVPN Premium for 30 days, risk-free. If you aren\'t entirely satisfied, we will give you a full refund.')}</div>
					</Box>
				</Box>: null
			}
		</Paper>
	) : null;
}

PaymentInfoV3.propTypes = {
	amexWarning: PropTypes.bool,
	paymentNotEligible: PropTypes.bool,
	paymentName: PropTypes.string,
	paymentDisplayName: PropTypes.string,
	_pf: PropTypes.object,
	_pft: PropTypes.object,
	_pfe: PropTypes.object,
	i18n: PropTypes.object,
	upgradeMode: PropTypes.bool,
	disabled: PropTypes.bool,
	btnProcessing: PropTypes.bool,
	isProxyDetected: PropTypes.bool,
	mbg: PropTypes.bool,
	info: PropTypes.bool,
	couponCode: PropTypes.string,
	couponByCode: PropTypes.object,
	couponByPlan: PropTypes.object,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponSavingPercent: PropTypes.string,
	planConfig: PropTypes.object,
	customClassName: PropTypes.string,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	classes: PropTypes.object,
	sentryProps: PropTypes.object,
	onChangePaymentMethod: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmit: PropTypes.func,
	onRemoveCouponByPlan: PropTypes.func,
	t: PropTypes.func,
	onUpdateSentryProps: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		sentryProps: state.home.sentryProps,
		couponByCode: state.home.couponByCode,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onRemoveCouponByPlan: dataToSend => dispatch(removeCouponByPlan(dataToSend)),
		onUpdateSentryProps: sentryProps => dispatch(updateSentryProps(sentryProps)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withNamespaces()(PaymentInfoV3)));